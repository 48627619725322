@import "variables";
@import "framework/framework";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/solid";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/regular";


html {
	background: $color-black;
	font-family: $primary-font;
	font-size: 16px;
	line-height: 1;
	width: 100%;
	overflow-x: hidden;

	@include desktop-sm-down {
		font-size: 15px;
	}

	@include tablet-down {
		font-size: 14px;
	}

	@include phone-down {
		font-size: 13px;
	}

	@include phone-sm-down {
		font-size: 12px;
	}
}

body {
	color: $color-white;
	width: 100%;
	overflow-x: hidden;
	position: relative;
	display: flex;
	flex-direction: column;
	min-height: 100vh;

	main {
		flex: 1;
	}
}

h1,
h2,
h3,
p,
a {
	text-transform: uppercase;
}

a {
	color: inherit;

	@include hover-focus {
		color: $color-white;
	}
}

.btn {}


.toggle-widget {
	min-width: rems(180);
	z-index: 100;
	position: fixed;
	left: rems(48);
	bottom: rems(20);
	border: 1px solid $color-white;
	background-color: $color-black;
	padding: rems(16);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	p {
		text-align: center;
		font-size: rems(11);
		font-style: italic;
		margin: 0 0 rems(20);

		&.ep1 {
			display: block;
		}

		&.ep1-symphonic {
			display: none;
		}
	}

	.toggle-wrapper {
		padding: 0;
		width: rems(64);
		display: flex;
		align-items: center;
		justify-content: center;

		a.btn-bg {
			width: 100%;
			height: rems(26);
			background-color: $color-red;
			border-radius: rems(100);
			display: flex;
			align-items: center;
			justify-content: flex-start;
			padding: rems(3);
			cursor: pointer;

			#toggle-switch {
				height: rems(20);
				width: rems(29);
				z-index: 101;
				background-color: $color-gray-pink;
				border-radius: rems(100);
				transition: all .3s ease-in-out;
				transform: translateX(0);
			}
		}
	}

	&.active {
		p {

			&.ep1 {
				display: none;
			}

			&.ep1-symphonic {
				display: block;
			}
		}

		.toggle-wrapper {
			a.btn-bg {
				#toggle-switch {
					transform: translateX(rems(25));
				}
			}
		}
	}
}

header {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	background-color: $color-black;
	z-index: 100000;
	padding: rems(20) 0 0;

	.container {
		padding-bottom: rems(20);

		.navbar {
			display: flex;
			align-items: center;
			justify-content: space-between;

			@include phone-down {
				flex-direction: column;
			}

			.nav-title {
				display: flex;
				align-items: center;

				img#logo {
					max-width: rems(80);
				}

				h1 {
					font-size: rems(28);
					font-weight: 400;
					margin: 0;
				}
			}

			.h-line {
				flex: 1;
				margin: 0 rems(40) 0 rems(20);
				height: 1px;
				background-color: $color-white;

				@include phone-down {
					display: none;
				}
			}

			nav {
				display: flex;
				align-items: center;
				gap: rems(20);

				@include phone-down {
					flex-direction: column;
					justify-content: center;
				}

				.promo {
					display: flex;
					align-items: center;
					gap: rems(20);
					padding: rems(10) 0;

					@include phone-down {
						// flex-direction: column;
						text-align: center;
					}


					.img-wrapper {
						width: rems(82);
						overflow: hidden;
						position: relative;
						aspect-ratio: 1/1;

						img {
							width: 100%;
							height: auto;
							transition: opacity 0.3s ease-in-out;


							&.active {

							}
						}

						.hover-image {
							position: absolute;
							top: 0;
							left: 0;
							opacity: 0;
							transition: opacity 0.3s ease-in-out;
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
					}

					.content {
						min-width: rems(140);
						display: flex;
						flex-direction: column;

						&.left {
							align-items: flex-end;
						}

						&.right {
							align-items: flex-start;
						}

						.title {
							font-size: rems(18);
							font-style: italic;
							margin-bottom: rems(4);
							text-transform: uppercase;
						}

						.description {
							font-size: rems(14);
							font-weight: 700;
							margin-bottom: rems(8);

							a {
								@include hover-focus {
									color: $color-red;
								}
							}
						}

						img.blocks {
							max-width: rems(72);
						}
					}
				}

				.v-line {
					margin: 0;
					width: 1px;
					align-self: stretch;
					background-color: $color-white;

					@include tablet-down {
						margin: 0 rems(20);
					}

					@include phone-down {
						display: none;
					}
				}

				.social {
					display: flex;
					gap: rems(22);

					a {
						display: inline-block;

						@include hover-focus {
							color: $color-red;
						}

						i {
							font-size: rems(23);
						}
					}
				}
			}
		}
	}

	.header-underline {
		height: 1px;
		width: 90vw;
		background-color: $color-white;

	}
}

main {
	padding-top: rems(120);

	@include phone-down {
		padding-top: rems(230);
	}

	.page-navigation {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: rems(82) 0 rems(108);
		position: relative;

		img.bg {
			position: absolute;
			left: rems(10);
			top: 50%;
			transform: translateY(-50%)
		}

		nav.navigation-widget {
			display: flex;
			max-width: rems(600);
			position: relative;
			z-index: 2;

			@include phone-down {
				padding: 0 rems(20);
			}

			a.navigation-item {
				position: relative;
				transition: all .3s ease-in-out;

				@include hover-focus {
					opacity: .6;
				}

				.image-wrapper {
					position: relative;
					width: 100%;
					height: auto;

					img {
						transition: opacity 0.5s ease-in-out;

						&.normal {
							opacity: 1; // Show normal image by default
						}

						&.mono {
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: auto;
							opacity: 0; // Start hidden
						}
					}
				}

				.text-wrapper {
					position: absolute;
					inset: 0;
					display: flex;
					align-items: center;
					justify-content: center;

					p {
						color: $color-white;
						opacity: 0.4;
						font-weight: 700;
						font-size: rems(75);

						@include phone-down {
							font-size: rems(48);
						}
					}
				}
			}

			&.active {
				a.navigation-item {
					.image-wrapper {
						img {
							&.normal {
								opacity: 0;
							}

							&.mono {
								opacity: 1;
							}
						}
					}
				}
			}
		}
	}

	section.part {
		margin-bottom: rems(200);
		position: relative;

		@include phone-down {
			margin-bottom: rems(150);
		}

		.bg-pictures-wrapper {
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
			pointer-events: none;

			img.bg-pictures {
				transition: opacity 0.5s ease-in-out;

				&.normal {
					opacity: 1; // Show normal image by default
				}

				&.mono {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: auto;
					opacity: 0; // Start hidden
				}
			}

			&.active {
				img.bg-pictures {
					&.normal {
						opacity: 0;
					}

					&.mono {
						opacity: 1;
					}
				}
			}
		}

		// img.bg-pictures {
		// 	position: absolute;
		// 	left: 0;
		// 	top: 50%;
		// 	transform: translateY(-50%);
		// 	pointer-events: none;
		// }

		img.bg-letters {
			position: absolute;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
			pointer-events: none;
		}

		.container {
			.arrow-wrapper {
				display: flex;
				gap: rems(80);
				position: relative;

				@include phone-down {
					flex-direction: column;
					gap: rems(10);
				}

				.info {
					flex: 1;
					padding: 0 0 rems(80) rems(20);

					@include phone-down {
						padding: 0 0 rems(20) rems(20);
					}

					.header-wrapper {
						position: relative;
						display: flex;
						padding: rems(20) 0;

						&::after {
							content: "";
							position: absolute;
							left: 50%;
							transform: translateX(-50%);
							bottom: 0;
							width: 400vw;
							height: 1px;
							background-color: $color-white;
							z-index: -1;
						}

						h2 {
							font-size: rems(32);
							font-style: italic;
							font-weight: 700;
							padding: 0;
							margin: 0;
							padding-right: rems(10);
							white-space: nowrap;
						}

						h3 {
							font-size: rems(32);
							font-weight: 400;
							padding: 0;
							margin: 0;
							white-space: nowrap;
						}
					}

					.blocks-wrapper {
						position: relative;
						margin: rems(20) 0;
						width: rems(280);

						img {
							transition: opacity 0.5s ease-in-out;
							width: 100%;

							&.normal {
								opacity: 1;
							}

							&.mono {
								position: absolute;
								top: 0;
								left: 0;
								width: 100%;
								height: auto;
								opacity: 0;
							}
						}

						&.active {
							img {
								&.normal {
									opacity: 0;
								}

								&.mono {
									opacity: 1;
								}
							}
						}
					}
				}

				.embed-wrapper {
					flex: 1.5;
					padding-bottom: rems(150);

					@include phone-down {
						padding: 0 rems(20) rems(100);
					}

					.embed-container {

						iframe {
							transition: all .3s ease-in-out;

							&.normal {
								opacity: 1;
							}

							&.mono {
								opacity: 0;
								pointer-events: none;
							}
						}
					}

					&.active {
						.embed-container {
							iframe {
								&.normal {
									opacity: 0;
									pointer-events: none;
								}

								&.mono {
									opacity: 1;
									pointer-events: all;
								}
							}
						}
					}
				}
			}
		}

		&.normal {
			.container {
				.arrow-wrapper {
					border-left: 1px solid $color-white;

					&::after {
						content: "\f0d7";
						position: absolute;
						top: 98%;
						left: 0;
						transform: translateX(-55%);
						font-family: "Font Awesome 6 Pro";
						font-weight: 700;

						@include tablet-down {
							top: 99%;
						}
					}

					&.notch {
						&::after {
							content: "\f111";
							top: 99%;
							font-size: rems(8);

							@include phone-down {
								top: 100%;
							}
						}
					}
				}
			}
		}

		&.reverse {
			.container {
				.arrow-wrapper {
					flex-direction: row-reverse;

					@include phone-down {
						flex-direction: column;
						border-right: 1px solid $color-white;

						&::after {
							content: "\f0d7";
							position: absolute;
							top: 98%;
							right: 0;
							transform: translateX(55%);
							font-family: "Font Awesome 6 Pro";
							font-weight: 700;

							@include tablet-down {
								top: 99%;
							}
						}
					}

					@include tablet-up {
						border: none;

						.info {
							position: relative;
							border-left: 1px solid $color-white;

							&::after {
								content: "\f0d7";
								position: absolute;
								top: 98%;
								left: 0;
								transform: translateX(-55%);
								font-family: "Font Awesome 6 Pro";
								font-weight: 700;

								@include tablet-down {
									top: 99%;
								}
							}
						}
					}
				}
			}

			.bg-pictures-wrapper {
				left: auto !important;
				right: 0 !important;
			}

			img.bg-letters {
				left: 0;
				right: auto;
			}
		}
	}

	.page-navigation-footer {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: rems(82) 0 rems(108);

		@include phone-down {
			padding: 0 0 rems(108);
		}

		nav.navigation-widget {
			display: flex;
			max-width: rems(600);
			position: relative;
			z-index: 2;

			@include phone-down {
				padding: 0 rems(20);
			}

			a.navigation-item {
				position: relative;
				transition: all .3s ease-in-out;

				@include hover-focus {
					opacity: .6;
				}

				.image-wrapper {
					position: relative;
					width: 100%;
					height: auto;

					img {
						transition: opacity 0.5s ease-in-out;

						&.normal {
							opacity: 1; // Show normal image by default
						}

						&.mono {
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: auto;
							opacity: 0; // Start hidden
						}
					}
				}

				.text-wrapper {
					position: absolute;
					inset: 0;
					display: flex;
					align-items: center;
					justify-content: center;

					p {
						color: $color-white;
						opacity: 0.4;
						font-weight: 700;
						font-size: rems(75);

						@include phone-down {
							font-size: rems(48);
						}
					}
				}
			}

			&.active {
				a.navigation-item {
					.image-wrapper {
						img {
							&.normal {
								opacity: 0;
							}

							&.mono {
								opacity: 1;
							}
						}
					}
				}
			}
		}
	}
}

footer {
	background-color: transparent;
	display: flex;
	align-items: center;
	gap: rems(186);
	padding-bottom: rems(75);
	position: relative;

	@include tablet-down {
		gap: rems(100);

	}

	@include phone-down {
		gap: rems(50);
	}

	img.bg {
		position: absolute;
		left: rems(10);
		bottom: 0;
		pointer-events: none;
	}

	.line {
		height: 1px;
		background-color: $color-white;
		flex: 1;
	}

	.blocks-wrapper {
		position: relative;
		margin: rems(20) 0;
		width: rems(280);

		img {
			transition: opacity 0.5s ease-in-out;
			max-width: rems(280);

			@include tablet-down {
				max-width: rems(200);
			}

			@include phone-down {
				max-width: rems(150);
			}

			&.normal {
				opacity: 1;
			}

			&.mono {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: auto;
				opacity: 0;
			}
		}

		&.active {
			img {
				&.normal {
					opacity: 0;
				}

				&.mono {
					opacity: 1;
				}
			}
		}
	}
}