$fa-font-path: "../font";
$html-font-size: 16;
$color-white: #FFFFFF;
$color-black: #0E0E0E;
$color-charcoal: #303030;
$color-red: #F0485C;
$color-gray-pink: #AB7E81;
$color-gray: #666d62;

$primary-font: 'Barlow', sans-serif;
